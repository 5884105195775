import { createRouter, createWebHistory } from "vue-router";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { $inj } from "@/common/decorators/depinject";
import WorkflowRouteGuard from "./WorkflowRouteGuard";
import { WorkflowFactory } from "@/common/services/services.module";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: WorkflowStatesConstant.ROOT.STATE,
      component: () => import("../AppModule.vue"),
      //component: () => import("../states/entry/continue/ContinueState.vue"),
      //props: (route) => ({ workspaceUUID: route.params.workspaceUUID, trackerUUID: route.query.trackerUUID }),
      children: [
        {
          path: "",
          name: WorkflowStatesConstant.ENTRY.STATE,
          component: () => import("../states/entry/EntryState.vue"),
          children: [
            {
              path: `/${WorkflowStatesConstant.START_PERSON.STATE}`,
              name: WorkflowStatesConstant.START_PERSON.STATE,
              component: () => import("../states/entry/start-person/StartPersonState.vue")
            },
            {
              path: `/${WorkflowStatesConstant.START_BUSINESS.STATE}`,
              name: WorkflowStatesConstant.START_BUSINESS.STATE,
              component: () => import("../states/entry/start-business/StartBusinessState.vue")
            },
            {
              path: `/w/:workflow`,
              name: WorkflowStatesConstant.START_CUSTOM.STATE,
              component: () => import("../states/entry/start-custom/CustomEntry.vue"),
              props: (route) => ({ workflow: route.params.workflow })
            },
            {
              path: `/${WorkflowStatesConstant.CONTINUE.STATE}/:workspaceUUID`,
              name: WorkflowStatesConstant.CONTINUE.STATE,
              component: () => import("../states/entry/continue/ContinueState.vue"),
              props: (route) => ({ workspaceUUID: route.params.workspaceUUID, trackerUUID: route.query.trackerUUID }),
              beforeEnter: WorkflowRouteGuard.HandleNoPropsNavigation
            },
            {
              path: `/${WorkflowStatesConstant.BUSINESS_CONTINUE.STATE}/:workspaceUUID`,
              name: WorkflowStatesConstant.BUSINESS_CONTINUE.STATE,
              component: () => import("../states/entry/business-continue/BusinessContinueState.vue"),
              props: (route) => ({ workspaceUUID: route.params.workspaceUUID, trackerUUID: route.query.trackerUUID }),
              beforeEnter: WorkflowRouteGuard.HandleNoPropsNavigation
            },
            {
              path: `/${WorkflowStatesConstant.VALIDATE_TOKEN.STATE}/:workspaceUUID`,
              name: WorkflowStatesConstant.VALIDATE_TOKEN.STATE,
              component: () => import("../states/entry/validate-token/ValidateTokenState.vue")
            },
            {
              path: `/${WorkflowStatesConstant.BANNO_SSO_ORIGIN.STATE}`,
              name: WorkflowStatesConstant.BANNO_SSO_ORIGIN.STATE,
              component: () => import("../states/entry/banno-sso-origin/BannoSsoOriginState.vue"),
              props: (route) => ({ code: route.query.code, locationUrl: window.location.href })
            }
          ]
        },
        {
          path: `/${WorkflowStatesConstant.ERROR.STATE}`,
          name: WorkflowStatesConstant.ERROR.STATE,
          component: () => import("../states/error/ErrorState.vue")
        },
        {
          path: `/${WorkflowStatesConstant.WORKFLOW.STATE}/:workspaceUUID`,
          name: WorkflowStatesConstant.WORKFLOW.STATE,
          component: () => import("../states/workflow/WorkFlowState.vue"),
          beforeEnter: WorkflowRouteGuard.HandleUnwantedChildNavigation,
          children: [
            {
              path: ``,
              name: WorkflowStatesConstant.APPLICANT.STATE,
              component: () => import("../states/workflow/applicant/ApplicantState.vue"),
              children: [
                {
                  path: ``,
                  name: WorkflowStatesConstant.APPLICANT_ACTION.STATE,
                  component: () => import("../states/workflow/applicant/action/ApplicantActionState.vue"),
                  children: [
                    {
                      path: ``,
                      name: WorkflowStatesConstant.SET_PRIMARY.STATE,
                      component: () => import("../states/workflow/applicant/action/setPrimary/SetPrimaryState.vue")
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.SET_INVITE_PERSON_COMPLETE.STATE,
                      component: () =>
                        import(
                          "../states/workflow/applicant/action/setInvitePersonComplete/setInvitePersonCompleteState.vue"
                        )
                    }
                  ]
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PROMO_CODE.STATE,
                  component: () => import("../states/workflow/applicant/info/promoCode/PromoCode.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ADDRESS_HISTORY.STATE,
                  component: () => import("../states/workflow/applicant/info/addressHistory/AddressHistoryState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ADDRESS.STATE,
                  component: () => import("../states/workflow/applicant/info/address/AddressState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.BUSINESS_INFO.STATE,
                  component: () => import("../states/workflow/applicant/info/businessInfo/BusinessInfoState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.CAPTURE.STATE,
                  component: () => import("../states/workflow/applicant/info/capture/CaptureState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.APPLICANT_CIP.STATE,
                  component: () => import("../states/workflow/applicant/info/cip/ApplicantCipState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.DEMOGRAPHICS.STATE,
                  component: () => import("../states/workflow/applicant/info/demographics/DemographicsState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.LOAN_DEMOGRAPHICS.STATE,
                  component: () => import("../states/workflow/applicant/info/loanDemographics/LoanDemographicsState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.DOCUMENTS.STATE,
                  component: () => import("../states/workflow/applicant/info/documents/DocumentState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.EMAIL.STATE,
                  component: () => import("../states/workflow/applicant/info/email/EmailState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.EMPLOYMENT.STATE,
                  component: () => import("../states/workflow/applicant/info/employment/EmploymentState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.EMPLOYMENT_HISTORY.STATE,
                  component: () => import("../states/workflow/applicant/info/employmentHistory/EmploymentHistoryState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.EXISTING.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/existingApplicant/ExistingApplicantState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.EXPENSES.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/expenses/ExpensesState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.IDENTIFICATION.STATE,
                  component: () => import("../states/workflow/applicant/info/identification/IdentificationState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.INCOME.STATE,
                  component: () => import("../states/workflow/applicant/info/income/incomeState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.INITIAL_DISCLOSURE.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/initialDisclosure/InitialDisclosureState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.INVITE_APPLICANTS_STATUS.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/inviteApplicants/InviteApplicantsState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.LOAN_OFFER.STATE,
                  component: () => import('../states/workflow/applicant/info/loanSimpleUnsecured/loanSimpleUnsecured.vue')
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.LOAN_SIMPLE_UNSECURED.STATE,
                  component: () =>
                    import('../states/workflow/applicant/info/loanOffer/loanOffer.vue')
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.LOAN_FUNDING.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/loanFunding/loanFundingState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.LOAN_SUMMARY.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/loanSummary/loanSummaryState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PERSON_INFO.STATE,
                  component: () => import("../states/workflow/applicant/info/personInfo/PersonInfoState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PHONE.STATE,
                  component: () => import("../states/workflow/applicant/info/phone/PhoneState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PREQUALIFICATION.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/prequalification/PrequalificationState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SIGNATURE.STATE,
                  component: () => import("../states/workflow/applicant/info/signature/SignatureState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SSO.STATE,
                  component: () => import("../states/workflow/applicant/info/single-sign-on/SingleSignOnState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.WALL.STATE,
                  component: () => import("../states/workflow/applicant/info/wall/WallState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.WELCOME.STATE,
                  component: () => import("../states/workflow/applicant/info/twoemail/TwoEmailState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ADDRESS_CONFIRMATION.STATE,
                  component: () => import("../states/workflow/applicant/info/addressConfirmation/addressConfirmation.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ADDITIONAL_INCOME.STATE,
                  component: () => import("../states/workflow/applicant/info/additionalIncome/additionalIncomeState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ERROR_LOAN_NOA.STATE,
                  component: () => import("../states/workflow/applicant/info/loanNoa/loanNoaState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.LOAN_DECLARATION_QUESTIONS.STATE,
                  component: () => import("../states/workflow/applicant/info/loanDeclaration/loanDeclarationState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ASSETS.STATE,
                  component: () => import("../states/workflow/applicant/info/assets/AssetsState.vue")
                }
              ]
            },
            {
              path: ``,
              name: WorkflowStatesConstant.WORKFLOW_ACTION.STATE,
              component: () => import("../states/workflow/action/WorkflowActionState.vue"),
              children: [
                {
                  path: ``,
                  name: WorkflowStatesConstant.CREATE_NEW_APPLICANT.STATE,
                  component: () => import("../states/workflow/action/createNewApplicant/CreateNewApplicantState.vue"),
                  props: (route) => ({
                    workspaceUUID: route.params.workspaceUUID,
                    trackerUUID: route.query.trackerUUID
                  })
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.CREATE_NEW_BUSINESS_APPLICANT.STATE,
                  component: () =>
                    import("../states/workflow/action/createNewApplicant/CreateNewBusinessApplicantState.vue"),
                  props: (route) => ({
                    workspaceUUID: route.params.workspaceUUID,
                    trackerUUID: route.query.trackerUUID
                  })
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SET_BUSINESS_ACTIVE.STATE,
                  component: () => import("../states/workflow/action/setBusinessActive/setBusinessActiveState.vue"),
                  props: (route) => ({
                    workspaceUUID: route.params.workspaceUUID,
                    trackerUUID: route.query.trackerUUID
                  })
                }
              ]
            },
            {
              path: ``,
              name: WorkflowStatesConstant.ENROLLMENT.STATE,
              component: () => import("../states/workflow/enrollment/EnrollmentState.vue"),
              children: [
                {
                  path: ``,
                  name: WorkflowStatesConstant.COLLATERAL.STATE,
                  component: () =>
                      import("../states/workflow/enrollment/info/collateral/collateralState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.BUSINESS_OWNERSHIP.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/businessOwnership/BusinessOwnershipState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.BUSINESS_RESOLUTION.STATE,
                  component: () =>
                    import("../states/workflow/applicant/info/businessResolution/BusinessResolutionState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ENROLLMENT_ACTION.STATE,
                  component: () => import("../states/workflow/enrollment/action/EnrollmentActionState.vue"),
                  children: [
                    {
                      path: ``,
                      name: WorkflowStatesConstant.AUTO_ADD_SERVICES.STATE,
                      component: () =>
                        import("../states/workflow/enrollment/action/autoAddServices/AutoAddServicesState.vue")
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.BANNO_ENROLL.STATE,
                      component: () => import("../states/workflow/enrollment/action/bannoEnroll/BannoEnrollState.vue")
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.CONFIGURE_SHARE_ACCOUNTS.STATE,
                      component: () =>
                        import(
                          "../states/workflow/enrollment/action/configureShareAccounts/ConfigureShareAccountsState.vue"
                        )
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.LINK_ENROLLMENT_APPLICANTS.STATE,
                      component: () =>
                        import(
                          "../states/workflow/enrollment/action/linkEnrollmentApplicants/LinkEnrollmentApplicantsState.vue"
                        )
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.LINK_ONE_ENROLLMENT_APPLICANT.STATE,
                      component: () =>
                        import(
                          "../states/workflow/enrollment/action/linkOneEnrollmentApplicant/LinkOneEnrollmentApplicantState.vue"
                        )
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.SEND_DISCLOSURE_EMAILS.STATE,
                      component: () =>
                        import(
                          "../states/workflow/enrollment/action/sendDisclosureEmails/SendDisclosureEmailsState.vue"
                        )
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.SET_OWNERSHIP.STATE,
                      component: () => import("../states/workflow/enrollment/action/setOwnership/SetOwnershipState.vue")
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.SET_SUGGESTED_ROLES.STATE,
                      component: () =>
                        import("../states/workflow/enrollment/action/setSuggestedRoles/SetSuggestedRolesState.vue")
                    },
                    {
                      path: ``,
                      name: WorkflowStatesConstant.SET_SUGGESTED_TITLES.STATE,
                      component: () =>
                        import("../states/workflow/enrollment/action/setSuggestedTitles/SetSuggestedTitlesState.vue")
                    }
                  ]
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ACCOUNT_APPLICANT_DISCLOSURE.STATE,
                  component: () =>
                    import(
                      "../states/workflow/enrollment/info/accountApplicantDisclosure/AccountApplicantDisclosureState.vue"
                    )
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ERROR_NOA.STATE,
                  component: () => import("../states/error/noa/NoaState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.BRANCH_SELECTION.STATE,
                  component: () => import("../states/workflow/enrollment/info/branchLocation/BranchLocationState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.CHOOSE_PRIMARY.STATE,
                  component: () => import("../states/workflow/enrollment/info/chooseprimary/ChoosePrimaryState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ENROLLMENT_CIP.STATE,
                  component: () => import("../states/workflow/enrollment/info/cip/EnrollmentCipState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.ENROLLMENT_ERROR.STATE,
                  component: () => import("../states/workflow/enrollment/info/error/EnrollmentErrorState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.FINAL_DISCLOSURE.STATE,
                  component: () => import("../states/workflow/enrollment/info/finalDisclosure/FinalDisclosureState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.FUNDING.STATE,
                  component: () => import("@/open/states/workflow/enrollment/info/funding/funding.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PERSON_RELATIONSHIP.STATE,
                  component: () =>
                    import("../states/workflow/enrollment/info/personRelationships/PersonRelationshipState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PRODUCT_GUIDE.STATE,
                  component: () => import("../states/workflow/enrollment/info/productGuide/ProductGuideState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.PRODUCT_SELECTION.STATE,
                  component: () =>
                    import("../states/workflow/enrollment/info/productSelection/ProductSelectionState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.REFERENCES.STATE,
                  component: () =>
                    import("../states/workflow/enrollment/info/references/ReferencesState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.RIGHT_OF_SURVIVORSHIP.STATE,
                  component: () =>
                    import("../states/workflow/enrollment/info/rightOfSurvivorship/RightOfSurvivorshipState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.RULE_DECISION.STATE,
                  component: () => import("../states/workflow/enrollment/info/ruleDecision/RuleDecisionState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SAVE.STATE,
                  component: () => import("../states/workflow/enrollment/info/save/SaveState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SERVICES.STATE,
                  component: () => import("../states/workflow/enrollment/info/services/ServicesState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SHOW_DISCLOSURE.STATE,
                  component: () => import("../states/workflow/enrollment/info/showDisclosure/ShowDisclosureState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.SUMMARY.STATE,
                  component: () => import("../states/workflow/enrollment/info/summary/SummaryState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.VALIDATE_PLAID.STATE,
                  component: () => import("../states/workflow/enrollment/info/validatePlaid/ValidatePlaidState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.WOLTERS_KLUWER.STATE,
                  component: () => import("../states/workflow/enrollment/info/woltersKluwer/WoltersKluwerState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.CONGRATULATIONS.STATE,
                  component: () => import("../states/workflow/enrollment/info/end/EndState.vue")
                },
                {
                  path: ``,
                  name: WorkflowStatesConstant.BENEFICIARY_INFO.STATE,
                  component: () => import("../states/workflow/enrollment/info/beneficiaries/BeneficiaryInfo.vue")
                },
              ]
            },
            {
              path: ``,
              name: WorkflowStatesConstant.INTERIM.STATE,
              component: () => import("../states/workflow/interim/InterimState.vue")
            },
            {
              path: ``,
              name: WorkflowStatesConstant.DECISION.STATE,
              component: () => import("../states/workflow/decision/DecisionState.vue")
            }
          ]
        },
        {
          path: `/sso/:providerUuid/workflow/:startWorkflowCode`,
          name: WorkflowStatesConstant.SSO_V0.STATE,
          component: () => import("../states/entry/sso-v0/SsoV0.vue")
        },
        {
          path: `/${WorkflowStatesConstant.SSO_REDIRECT.PATH}`,
          name: WorkflowStatesConstant.SSO_REDIRECT.STATE,
          component: () => import("../states/sso-redirect/SsoRedirectState.vue"),
          props: (route) => ({ code: route.query.code, state: route.query.state, locationUrl: window.location.href })
        },
        {
          path: `/${WorkflowStatesConstant.PLAID_AUTH.PATH}`,
          name: WorkflowStatesConstant.PLAID_AUTH.STATE,
          component: () => import("../states/plaid-oauth/PlaidOauthState.vue")
        },
      ]
    },
    {
      path: "/:catchAll(.*)",
      name: WorkflowStatesConstant.NOT_FOUND.STATE,
      redirect: WorkflowStatesConstant.START_PERSON.STATE
    }
  ]
});

router.onError((error: any) => {
  const workflowFactory = $inj(WorkflowFactory);
  workflowFactory.setEndPreloadingStatus();
  if (error.message && error.message.includes("Failed to fetch dynamically imported module")) {
    router.go(0);
  }
});

export default router;
